import * as Turbo from '@hotwired/turbo'

import { Controller } from '@hotwired/stimulus'

export class BulkActionsController extends Controller {
  updateStatuses(status: string, uuids: string[]) {
    // Prepare the CSRF token
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken = csrfMetaTag ? csrfMetaTag.getAttribute('content') : null
    const params = new URLSearchParams(window.location.search)

    fetch(`users/update_statuses`, {
      method: 'PATCH',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken || '',
      },
      body: JSON.stringify({
        status: status,
        uuids: uuids,
        page: params.get('page'),
        items: params.get('items'),
      }),
    })
      .then((response) => response.text())
      .then((turboStreamResponse) => {
        // If the response is a Turbo Stream template, apply it.
        // `Turbo.renderStreamMessage` will process the stream and
        // update the DOM accordingly.
        if (turboStreamResponse.includes('<turbo-stream')) {
          Turbo.renderStreamMessage(turboStreamResponse)
        } else {
          console.warn(
            'Received a non-turbo-stream response:',
            turboStreamResponse,
          )
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
  sendValues(status: string, selector: string) {
    // Gather all checked checkbox values
    const selectedValues = Array.from(document.querySelectorAll(selector))
      .filter((checkbox: HTMLInputElement) => checkbox.checked)
      .map((checkbox: HTMLInputElement) => checkbox.value)

    this.updateStatuses(status, selectedValues)
  }

  activateConfirmation(event: CustomEvent) {
    if (event.detail) {
      let props = event.detail
      this.sendValues(props.status, props.selector)
    }
  }

  deactivateConfirmation(event: CustomEvent) {
    if (event.detail) {
      let props = event.detail
      this.sendValues(props.status, props.selector)
    }
  }
  suspendConfirmation(event: CustomEvent) {
    if (event.detail) {
      let props = event.detail
      this.sendValues(props.status, props.selector)
    }
  }
}
