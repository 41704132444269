import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace'
import {
  DispatchEvent,
  HTMLElementEvent,
  MapboxAddressFeatureCoordinates,
  StateOrProvince,
} from '../types'
import { dispatcher } from './pub_controller'
import { MapboxAddress } from '../models'
import { Geocode } from '../models'
import { LocalClient } from '../clients'
import { targetUpdate } from './components/component_controller'
import { MapboxController } from './mapbox_controller'
import { Location, PhysicalAddress } from '../types/api'
import { isValidLatlongOrBlank, clearChildrenBySelector } from '../utils'
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '../constants'

export const defaultLatlong = [DEFAULT_LATITUDE, DEFAULT_LONGITUDE].join(',')

export class VerifiedAddressController extends MapboxController {
  static targets = [
    'city',
    'county',
    'country',
    'destroyFlag',
    'lineOne',
    'lineOneLabel',
    'lineTwo',
    'postalCode',
    'stateOrProvince',
    'timeZone',
    'latlong',
    'verifiedLatlong',
    'overrideLatlong',
    'overrideLatlongError',
  ]

  static values = {
    pubChannel: { type: String, default: 'address' },
    invalidLatlongError: String,
    unverifiedLineOneError: String,
    unverifiedLineOneLabelError: String,
    unverifiedLatlongError: String,
  }

  // Form elements
  declare readonly cityTarget: HTMLInputElement
  declare readonly hasCityTarget: boolean
  declare readonly countyTarget: HTMLInputElement
  declare readonly hasCountyTarget: boolean
  declare readonly countryTarget: HTMLSelectElement
  declare readonly hasCountryTarget: boolean
  declare readonly destroyFlagTarget: HTMLInputElement
  declare readonly lineOneTarget: HTMLInputElement
  declare readonly hasLineOneTarget: boolean
  declare readonly lineOneLabelTarget: HTMLInputElement
  declare readonly hasLineOneLabelTarget: boolean
  declare readonly lineTwoTarget: HTMLInputElement
  declare readonly hasLineTwoTarget: boolean
  declare readonly postalCodeTarget: HTMLInputElement
  declare readonly hasPostalCodeTarget: boolean
  declare readonly stateOrProvinceTarget: HTMLSelectElement
  declare readonly hasStateOrProvinceTarget: boolean
  declare readonly timeZoneTarget: HTMLSelectElement
  declare readonly hasTimeZoneTarget: boolean
  declare readonly verifiedLatlongTarget: HTMLInputElement
  declare readonly hasVerifiedLatlongTarget: boolean
  declare readonly overrideLatlongTarget: HTMLInputElement
  declare readonly overrideLatlongErrorTarget: HTMLElement
  declare readonly hasOverrideLatlongTarget: boolean

  // The pub channel, used to publish the geocode (see below)
  declare readonly pubChannelValue: string

  // Localized text for help and error messages
  declare readonly invalidLatlongErrorValue: string
  declare readonly unverifiedLineOneErrorValue: string
  declare readonly unverifiedLineOneLabelErrorValue: string
  declare readonly unverifiedLatlongErrorValue: string

  // The geocode represents the state of the latlong fields
  // (though it could use other fields as well in the future)
  // and is what gets sent to (say) map and legend components.
  // Possible states for `this.geocode`:
  // - `null`: the form is fresh (it has been cleared, or it
  //   initialized with neither latlong present)
  // - Verified latlong is present: The address is considered verified
  // - Override latlong is present: The address is considered overridden
  declare geocode: Geocode

  // Track the state of visible error messages so we can avoid
  // creating duplicates without asking the DOM
  declare hasInvalidOverrideLatlongError: boolean
  declare hasUnverifiedAddressError: boolean

  connect() {
    this.setTokens()
    this.hasInvalidOverrideLatlongError = false
    this.hasUnverifiedAddressError = false

    this.element.addEventListener('change', this.clearDestroyFlag)

    // If either value is set by the server (on edit, say) we initialize
    // a geocode; otherwise we make geocode `null` to signal a clear form
    const verifiedLatlong = this.verifiedLatlongFromDOM()
    const overrideLatlong = this.overrideLatlongFromDOM()

    if (verifiedLatlong || overrideLatlong) {
      this.geocode = new Geocode({
        verifiedLatlong: this.verifiedLatlongFromDOM(),
        overrideLatlong: this.overrideLatlongFromDOM(),
      })
    } else {
      this.geocode = null
    }

    // update UI text and publish the geocode state for other page elements
    setTimeout(() => this.syncGeocode(), 500)
  }

  disconnect() {
    this.element.removeEventListener('change', this.clearDestroyFlag)
  }

  // explicit user interaction with a form field other than latlong
  // and suggested address selection
  public handleInput(event: HTMLElementEvent<HTMLElement>): void {
    if (this.isClear()) {
      // the form has been interacted with, so it's no longer clear
      this.geocode = new Geocode({
        verifiedLatlong: null,
        overrideLatlong: this.geocode ? this.geocode.overrideLatlong : null,
      })
    }
    this.clearVerifiedLatlong()
    this.syncGeocode()
  }

  // selection of a suggestion address
  public async handleAddressSelection(
    event: HTMLElementEvent<HTMLElement>,
  ): Promise<void> {
    const mapboxAddress = await this.fetchMapboxAddress(event)
    this.setVerifiedAddress(mapboxAddress)
    this.syncGeocode()
  }

  // latlong form input (on blur)
  public handleLatlongInputOverride(
    event: HTMLElementEvent<HTMLInputElement>,
  ): void {
    const latlong = event.target.value
    if (isValidLatlongOrBlank(latlong)) {
      this.clearInvalidOverrideLatlongError()
      this.setOverrideLatlong(latlong)
      this.syncGeocode()
    }
  }

  // latlong form input (on input)
  public validateOverrideLatlong(
    event: HTMLElementEvent<HTMLInputElement>,
  ): void {
    const latlong = event.target.value
    if (isValidLatlongOrBlank(latlong)) {
      this.clearInvalidOverrideLatlongError()
    } else if (!this.hasInvalidOverrideLatlongError) {
      this.setInvalidOverrideLatlongError()
    }
  }

  // a pin drop or override button
  public handleMapOverride({ detail: latlong }: DispatchEvent<string>): void {
    const overrideLatlong =
      (latlong && latlong.value) || this.verifiedLatlong() || defaultLatlong
    this.setOverrideLatlong(overrideLatlong)
    this.syncGeocode()
  }

  // disengage the override from the button on the map legend
  public handleMapReset(): void {
    this.clearOverrideLatlong()
    this.syncGeocode()
  }

  // clear the form from the button
  public handleFormClear(): void {
    this.geocode = null
    this.clearAddressFields()
    this.syncGeocode()
  }

  handleCloneAddressEvent({ detail: address }: DispatchEvent<PhysicalAddress>) {
    this.setPhysicalAddressFields(address)
  }

  // the user selected a country code
  public async handleCountryCodeSelectionEvent({
    detail,
  }: DispatchEvent<string>): Promise<void> {
    const currentValue = this.stateOrProvinceTarget.querySelector('input').value
    const options = await this.fetchStateOrProvinceOptions(detail.value)
    const value = options.map((o) => o.value).includes(currentValue)
      ? currentValue
      : ''

    this.setStateOrProvinceValue(value, options)
  }

  // END OF PUBLIC INTERFACE -- EVERYTHING BELOW HERE IS/SHOULD BE PRIVATE

  private isClear(): boolean {
    return !this.geocode
  }

  private isVerified(): boolean {
    return this.geocode && this.geocode.isVerified()
  }

  private isOverridden(): boolean {
    return this.geocode && this.geocode.isOverridden()
  }

  private verifiedLatlong(): string | null {
    return (this.geocode && this.geocode.verifiedLatlong) || null
  }

  private overrideLatlong(): string | null {
    return (this.geocode && this.geocode.overrideLatlong) || null
  }

  private syncGeocode(): void {
    // add/remove form text
    this.syncUnverifiedText()
    // update the visible latlong field
    this.syncVisibleLatlong()
    // inform other channel consumers, e.g., map with legend
    if (this.pubChannelValue) {
      dispatcher(this, 'geocode', this.pubChannelValue, this.geocode)
    }
  }

  private syncUnverifiedText(): void {
    if (this.hasOverrideLatlongTarget) {
      if (this.isOverridden() || this.isVerified() || this.isClear()) {
        this.clearUnverifiedText()
      } else if (
        !this.isClear() &&
        !this.isOverridden() &&
        !this.hasUnverifiedAddressError
      ) {
        this.setUnverifiedText()
      }
    }
  }

  private syncVisibleLatlong() {
    if (this.hasOverrideLatlongTarget) {
      const placeholderText =
        (this.geocode && this.geocode.verifiedLatlong) || ''
      this.overrideLatlongTarget.setAttribute('placeholder', placeholderText)
    }
  }

  private clearAddressFields() {
    this.setLineOneValue('')
    this.setLineTwoValue('')
    this.setCityValue('')
    if (this.hasCountyTarget) {
      this.setCountyValue('')
    }
    this.setCountryValue('')
    this.setStateOrProvinceValue('', [])
    this.setPostalCodeValue('')
    this.setTimeZoneValue(null)
    this.clearLatlongs()
    this.setDestroyFlag()

    this.dispatch('address-cleared')
  }

  private async setVerifiedAddress(address: MapboxAddress): Promise<void> {
    this.setVerifiedLatlong(address.latlong)

    const stateOrProvinceOptions = await this.fetchStateOrProvinceOptions(
      address.country,
    )

    this.setLineOneValue(address.lineOne)
    this.setCityValue(address.city)
    this.setCountyValue(address.county)
    this.setCountryValueQuietly(address.country)
    this.setStateOrProvinceValue(
      address.stateOrProvince,
      stateOrProvinceOptions,
    )
    this.setPostalCodeValue(address.postalCode)
    this.setTimeZoneValue(address.coordinates)
  }

  private async setPhysicalAddressFields(address: PhysicalAddress) {
    const stateOrProvinceOptions = await this.fetchStateOrProvinceOptions(
      address.country_code,
    )

    this.setLineOneValue(address.line_one)
    this.setCityValue(address.city)
    this.setCountryValueQuietly(address.country_code)
    this.setStateOrProvinceValue(
      address.state_or_province,
      stateOrProvinceOptions,
    )
    this.setPostalCodeValue(address.postal_code)
  }

  private setLineOneValue(value: string) {
    this.lineOneTarget.value = value
    this.lineOneTarget.dispatchEvent(new Event('blur'))
  }

  private setLineTwoValue(value: string) {
    this.lineTwoTarget.value = value
    this.lineTwoTarget.dispatchEvent(new Event('blur'))
  }

  private setCityValue(value: string) {
    this.cityTarget.value = value
    this.cityTarget.dispatchEvent(new Event('blur'))
  }

  private setCountyValue(value: string) {
    if (this.hasCountyTarget) {
      this.countyTarget.value = value
      this.countyTarget.dispatchEvent(new Event('blur'))
    }
  }

  private setStateOrProvinceValue(value: string, options: StateOrProvince[]) {
    targetUpdate(this.stateOrProvinceTarget, { value, options })
  }

  private setPostalCodeValue(value: string) {
    this.postalCodeTarget.value = value
    this.postalCodeTarget.dispatchEvent(new Event('blur'))
  }

  private setCountryValue(value: string) {
    targetUpdate(this.countryTarget, { value })
  }

  private setCountryValueQuietly(value: string) {
    targetUpdate(this.countryTarget, { value, pubQuiet: true })
  }

  private setTimeZoneValue(coordinates: MapboxAddressFeatureCoordinates) {
    if (this.hasTimeZoneTarget) {
      if (coordinates) {
        const timeZone = getFuzzyLocalTimeFromPoint(Date.now(), [
          coordinates.longitude,
          coordinates.latitude,
        ])
        targetUpdate(this.timeZoneTarget, { value: timeZone._z.name })
      } else {
        targetUpdate(this.timeZoneTarget, { value: '' })
      }
    }
  }

  private clearLatlongs(): void {
    this.clearOverrideLatlong()
    this.clearVerifiedLatlong()
  }

  private clearOverrideLatlong(): void {
    this.setOverrideLatlong(null)
  }

  private clearVerifiedLatlong(): void {
    this.setVerifiedLatlong(null)
  }

  private setVerifiedLatlong(value: string) {
    if (this.hasVerifiedLatlongTarget) {
      if (this.geocode) {
        this.geocode.verifiedLatlong = value
      } else if (value) {
        this.geocode = new Geocode({
          verifiedLatlong: value,
          overrideLatlong: null,
        })
      }
      this.verifiedLatlongTarget.value = value
    }
  }

  private setOverrideLatlong(value: string) {
    if (this.hasOverrideLatlongTarget) {
      if (this.geocode) {
        this.geocode.overrideLatlong = value
      } else if (value) {
        this.geocode = new Geocode({
          verifiedLatlong: null,
          overrideLatlong: value,
        })
      }
      this.overrideLatlongTarget.value = value
    }
  }

  private setDestroyFlag() {
    this.destroyFlagTarget.value = 'true'
  }

  private clearDestroyFlag = () => {
    this.destroyFlagTarget.value = 'false'
  }

  private async fetchStateOrProvinceOptions(countryCode: string) {
    const { options } =
      await LocalClient.getStatesAndProvincesFromCountryCode(countryCode)
    return options
  }

  private async fetchMapboxAddress(event: HTMLElementEvent<HTMLElement>) {
    const { id, json } = event.target.dataset
    const { context, full_address } = JSON.parse(json)

    const forwardGeocodeAddressPayload =
      await LocalClient.fetchSuggestedAddressDetails(full_address)

    const addressProperties =
      forwardGeocodeAddressPayload.features[0]?.properties

    return new MapboxAddress(
      addressProperties.mapbox_id || id,
      addressProperties.context || context,
      addressProperties.coordinates,
    )
  }

  private verifiedLatlongFromDOM(): string {
    return this.hasVerifiedLatlongTarget ? this.verifiedLatlongTarget.value : ''
  }

  private overrideLatlongFromDOM(): string {
    return this.hasOverrideLatlongTarget ? this.overrideLatlongTarget.value : ''
  }

  private clearInvalidOverrideLatlongError() {
    clearChildrenBySelector(this.element, 'span.override-latlong-error')
    this.hasInvalidOverrideLatlongError = false
  }

  private setInvalidOverrideLatlongError() {
    const error = document.createElement('span')
    error.setAttribute(
      'class',
      'form__field-error-message override-latlong-error',
    )
    error.innerText = this.invalidLatlongErrorValue
    this.overrideLatlongTarget.after(error)
    this.hasInvalidOverrideLatlongError = true
  }

  private setUnverifiedText() {
    const lineOneLabelError = document.createElement('span')
    lineOneLabelError.setAttribute('class', 'unverified-address-error')
    lineOneLabelError.innerText = this.unverifiedLineOneLabelErrorValue
    this.lineOneLabelTarget.after(lineOneLabelError)

    const lineOneError = document.createElement('span')
    lineOneError.setAttribute('class', 'unverified-address-error')
    lineOneError.innerText = this.unverifiedLineOneErrorValue
    this.lineOneTarget.after(lineOneError)

    const latlongLabelError = document.createElement('span')
    latlongLabelError.setAttribute('class', 'unverified-address-error')
    latlongLabelError.innerText = this.unverifiedLatlongErrorValue
    this.overrideLatlongTarget.after(latlongLabelError)

    this.hasUnverifiedAddressError = true
  }

  private clearUnverifiedText() {
    clearChildrenBySelector(this.element, 'span.unverified-address-error')
    this.hasUnverifiedAddressError = false
  }
}
