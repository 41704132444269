import mapboxgl from 'mapbox-gl'
import { Geocode } from '../models'
import { DispatchEvent } from '../types'
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  OVERRIDE_MARKER_COLOR,
  DEFAULT_FLY_TO_ZOOM,
} from '../constants'
import { MapboxAddress } from '../models'
import { dispatcher } from './pub_controller'
import { defaultLatlong } from './verified_address_controller'
import { GeocodeableAddressableMapController } from './geocodeable_addressable_map_controller'

export class OverrideableGeocodeableAddressableMapController extends GeocodeableAddressableMapController {
  overrideMarker?: mapboxgl.Marker = undefined
  overrideMarkerEl?: HTMLImageElement = undefined
  verifiedMarker?: mapboxgl.Marker = undefined
  verifiedMarkerEl?: HTMLImageElement = undefined
  static targets = ['map']
  static values = {
    pubChannel: String,
    overrideMarkerUrl: String,
    verifiedMarkerUrl: String,
  }
  declare readonly addMapMarkerButtonTarget: HTMLButtonElement
  declare readonly hasAddMapMarkerButtonTarget: boolean
  declare readonly mapTarget: HTMLInputElement
  declare readonly hasMapTarget: boolean
  declare readonly pubChannelValue: string
  declare readonly overrideMarkerUrlValue: string
  declare readonly verifiedMarkerUrlValue: string

  declare geocode: Geocode

  connect() {
    this.setTokens()
    this.initializeMap(this.mapTarget)
    this.overrideMarkerEl = this.createMarkerElement(
      this.overrideMarkerUrlValue,
    )
    this.verifiedMarkerEl = this.createMarkerElement(
      this.verifiedMarkerUrlValue,
    )
  }

  public handleGeocodeUpdate({
    detail: geocode,
  }: DispatchEvent<Geocode>): void {
    this.geocode = geocode

    if (geocode) {
      // we consider an override latlong on the fallback a "verified" one here
      // because the user can't actually clear an override on the fallback record
      const verified = geocode.getVerifiedLatlong()

      if (verified) {
        const [latitude, longitude] = verified.split(',')
        this.setVerifiedMarker({ latitude, longitude })
      } else {
        this.clearMarkers([this.verifiedMarker])
      }

      if (geocode.overrideLatlong) {
        const [latitude, longitude] = geocode.overrideLatlong.split(',')
        this.setOverrideMarker({ latitude, longitude })
      } else {
        this.clearMarkers([this.overrideMarker])
      }
    } else {
      this.clearMarkers()
    }
  }

  private setOverrideMarker({
    latitude = DEFAULT_LATITUDE,
    longitude = DEFAULT_LONGITUDE,
    shouldFlyToMarkers = true,
  }) {
    this.clearMarkers([this.overrideMarker])

    const marker = this.addMarker(latitude, longitude, {
      draggable: true,
      element: this.overrideMarkerEl,
      color: OVERRIDE_MARKER_COLOR,
    })

    marker?.on('dragend', () => {
      const { lat, lng } = marker.getLngLat()

      this.sendOverride({ latlong: `${lat},${lng}` })
    })

    this.overrideMarker = marker

    shouldFlyToMarkers &&
      this.flyToMarkers({ opts: { maxZoom: DEFAULT_FLY_TO_ZOOM } })
  }

  private sendOverride({ latlong }): void {
    dispatcher(this, 'map-override', this.pubChannelValue, latlong)
  }

  protected resetMarkers(latitude: string, longitude: string) {
    this.clearMarkers()
    this.setVerifiedMarker({
      latitude,
      longitude,
      options: { element: this.verifiedMarkerEl },
    })
    this.setOverrideMarker({ latitude, longitude })
  }

  private clearOverrideMarker() {
    this.clearMarkers([this.overrideMarker])

    this.flyToMarkers()
  }

  private createMarkerElement(url: string) {
    const img = document.createElement('img')
    img.src = url
    img.height = 21
    img.width = 21
    return img
  }
}
