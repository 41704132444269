import { createElement } from 'react'
import { Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { ComponentController } from './component_controller'
import { dispatcher } from '../pub_controller'

type ModalProps = {
  body: string
  onCancel: any
  onOk: any
  open: boolean
  title: string
}

const ModalElement = ({ title, open, onOk, onCancel, body }: ModalProps) => {
  return createElement(
    Modal,
    {
      key: title,
      title: title,
      open: open,
      onOk: onOk,
      onCancel: onCancel,
      footer: ModalFooter({ onOk, onCancel }),
      className: 'modal',
    },
    ModalBody({ body, title }),
  )
}

const ModalFooter = ({ onOk, onCancel }: any) => {
  return createElement('div', null, [
    createElement(
      'Button',
      { key: 'back', className: 'modal-button--default', onClick: onCancel },
      'CANCEL',
    ),
    createElement(
      'Button',
      { key: 'submit', className: 'modal-button--confirm', onClick: onOk },
      'OK',
    ),
  ])
}

const ModalBody = ({ body, title }: any) => {
  return createElement('div', null, [
    createElement(CloseCircleOutlined, {
      key: title,
      className: 'modal-body-icon',
    }),
    createElement('span', { key: title, className: 'modal-body-text' }, body),
  ])
}

export class ModalController extends ComponentController {
  toggleModal() {
    const props = this.props as ModalProps
    this.updateProps({ open: !props.open })
  }

  modalConfirmation() {
    const props = this.propsFromData
    dispatcher(this, props.confirm_method, props.pub_channel, props)
  }

  get component() {
    return ModalElement
  }

  get initialProps() {
    const props = this.propsFromData
    return {
      ...props,
      onOk: this.modalConfirmation.bind(this),
      onCancel: this.toggleModal.bind(this),
    }
  }
}
