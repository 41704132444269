import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

export class CarouselController extends Controller {
  static targets = ['follower', 'leader']
  static values = { arrows: Boolean }

  declare followerTarget: HTMLElement
  declare hasLeaderTarget: boolean
  declare leaderTarget: HTMLElement
  declare arrowsValue: any

  splide: Splide

  connect() {
    const el = this.element as HTMLElement
    const follower = new Splide(this.followerTarget, {
      arrows: false,
      height: '282px',
      pagination: false,
      type: 'fade',
    })
    if (this.hasLeaderTarget) {
      const leader = new Splide(this.leaderTarget, {
        arrows: this.arrowsValue,
        cover: true,
        height: '56px',
        gap: '11.75px',
        isNavigation: true,
        pagination: false,
        perPage: 3,
        width: '199.75px',
      })
      follower.sync(leader)
      leader.mount()
    }
    follower.mount()
  }
}
