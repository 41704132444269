import { Controller } from '@hotwired/stimulus'

export class CustomStatusCheckQuestionsController extends Controller {
  static targets = ['questions', 'removedQuestions']
  static values = {
    buttonLabel: String,
    fieldName: String,
    questions: Array,
  }

  declare buttonLabelValue: string
  declare fieldNameValue: string
  declare questionsTarget: HTMLElement
  declare questionsValue: { question: string; id: string }[]
  declare removedQuestionsTarget: HTMLElement

  questionsCount = 0

  connect() {
    this.renderPersistedQuestions()
    this.createNewQuestion()
  }

  createNewQuestion() {
    this.renderQuestion('', null, (formRow) => () => {
      formRow.remove()
    })
  }

  renderPersistedQuestions() {
    this.questionsValue.forEach((question: any) => {
      this.renderQuestion(question.question, question.id, (formRow) => () => {
        formRow.remove()

        const idField = document.createElement('input')
        idField.type = 'hidden'
        idField.name = `${this.fieldNameValue}[${this.questionsCount}][id]`
        idField.value = question.id

        const destroyFlagField = document.createElement('input')
        destroyFlagField.type = 'hidden'
        destroyFlagField.name = `${this.fieldNameValue}[${this.questionsCount}][_destroy]`
        destroyFlagField.value = '1'

        this.removedQuestionsTarget.appendChild(idField)
        this.removedQuestionsTarget.appendChild(destroyFlagField)
      })
    })
  }

  renderQuestion(
    question: string,
    id: string | null,
    buildRemove: (formRow: HTMLElement) => () => void,
  ) {
    const questionIndex = this.questionsCount++
    const formRow = document.createElement('div')
    formRow.classList.add('form__field-row', 'col-1')

    const field = document.createElement('div')
    field.classList.add('form__field-wrapper')

    const removeButton = document.createElement('button')
    removeButton.textContent = this.buttonLabelValue
    removeButton.classList.add('button', 'button--small', 'button--secondary')
    removeButton.type = 'button'
    removeButton.disabled = !question
    removeButton.addEventListener('click', () => {
      buildRemove(formRow)()
      if (!this.questionsTarget.querySelector('textarea')) {
        this.createNewQuestion()
      }
    })

    const name = `${this.fieldNameValue}[${questionIndex}][question]`

    const label = document.createElement('label')
    label.textContent = 'Status Check Question'
    label.htmlFor = name
    label.appendChild(removeButton)

    const textarea = document.createElement('textarea')
    textarea.name = name
    textarea.id = name
    textarea.value = question
    textarea.addEventListener('input', () => {
      removeButton.disabled = !textarea.value
    })

    field.appendChild(label)
    if (id) {
      const idField = document.createElement('input')
      idField.type = 'hidden'
      idField.name = `${this.fieldNameValue}[${questionIndex}][id]`
      idField.value = id
      field.appendChild(idField)
    }
    field.appendChild(textarea)

    formRow.appendChild(field)

    this.questionsTarget.appendChild(formRow)
  }
}
