import { Select } from 'antd'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'

import { dispatcher } from '../pub_controller'
import { ComponentController } from './component_controller'
import { pushBinding } from '../binding_controller'
import { DispatchEvent, SelectOptionSingle } from '../../types'

export class MultiSelectController extends ComponentController<SelectProps> {
  static targets = ['component', 'count', 'input']

  declare componentTarget: HTMLElement
  declare countTarget: HTMLElement
  declare hasCountTarget: boolean
  declare inputTarget: HTMLInputElement

  update(event: DispatchEvent<{ content: SelectOptionSingle[] }>) {
    const value = this.getPropsFromEvent(event)['value']
    let values

    if (Array.isArray(value)) {
      values = value
    } else if (value && typeof value === 'string') {
      values = value.split(',')
    } else {
      values = []
    }

    const optionValues = this.props.options.map((option) => option.value)
    const missingValues = values.filter(
      (value) => !optionValues.includes(value),
    )

    if (missingValues.length > 0) {
      console.log(
        `[multi-select] missing option value(s): ${missingValues.join(', ')}`,
      )
      return
    }

    const selectedOptions = this.getSelectedOptions(this.props.options, values)
    this.updateTargets(values, selectedOptions)
    super.update(event)
  }

  get component() {
    return Select
  }

  get initialProps() {
    const props = this.propsFromData
    const initialValues = this.inputTarget.value
      ? this.getSelectedOptions(
          props.options,
          this.inputTarget.value.split(','),
        )
      : undefined
    return {
      ...props,
      defaultValue: initialValues,
      maxTagCount: 'responsive',
      maxTagPlaceholder: (ommittedValues: string[]) =>
        `+${ommittedValues.length}${props.size === 'small' ? '' : ' items'}`, // TODO: i18n
      onChange: this.onChange.bind(this),
      value: initialValues,
      virtual: false,
    }
  }

  onChange(values: string[]): void {
    const selectedOptions = this.getSelectedOptions(this.props.options, values)

    this.updateTargets(values, selectedOptions)
    this.updateProps({ value: values })
  }

  get rootElement() {
    return this.componentTarget
  }

  reset() {
    this.updateTargets([], [])
    this.updateProps({ options: [], value: [] })
  }

  private getSelectedOptions(
    options: DefaultOptionType[],
    value: any[],
  ): DefaultOptionType[] {
    return options
      .map((opt) => ('options' in opt ? opt.options : opt))
      .flat()
      .filter((opt) => 'value' in opt && value.includes(opt.value))
  }

  private updateTargets(values: string[], selectedOptions): void {
    this.inputTarget.value = values.join(',')
    this.updateCountTarget(values.length)
    // triggers the pub channel announcement of the select value
    this.inputTarget.dispatchEvent(new Event('change'))

    const pubChannel = this.inputTarget.getAttribute('data-pub-channel-value')
    const pubAs = this.inputTarget.getAttribute('data-pub-as-value')
    if (pubChannel && pubAs && this.props.mode !== 'tags') {
      this.dispatch('change', {
        prefix: 'select',
        detail: { value: selectedOptions },
      })

      // dispatch the label text for UI that needs that instead of the select value
      dispatcher(
        this,
        `${pubAs}_text`,
        pubChannel,
        selectedOptions.map((o) => o.label),
      )
    }
  }

  private updateCountTarget(count: number) {
    if (this.hasCountTarget) {
      this.countTarget.innerText = count != 0 ? `(${count})` : ''
    }
  }
}
