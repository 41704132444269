import { Controller } from '@hotwired/stimulus'
import {
  HTMLElementEvent,
  DispatchEvent,
  Location,
  SelectableUser,
} from '../types'
import { Geocode } from '../models'
import { LocalClient } from '../clients'
import { dispatcher } from './pub_controller'
import { defaultLatlong } from './verified_address_controller'
import { targetUpdate } from './components/component_controller'
import { isValidLatlongOrBlank, clearChildrenBySelector } from '../utils'

export class SiteForm extends Controller {
  static targets = [
    'latlongInput',
    'locationSelect',
    'primaryPointOfContact',
    'coordinators',
    'inspectors',
    'responders',
    'tagSelect',
    'overrideLatlong',
  ]

  static values = {
    locationVerifiedLatlong: String,
    locationOverrideLatlong: String,
    invalidLatlongError: String,
  }

  declare readonly overrideLatlongTarget: HTMLInputElement
  declare readonly locationSelectTarget: HTMLInputElement
  declare readonly hasOverrideLatlongTarget: boolean
  declare readonly primaryPointOfContactTarget: HTMLInputElement
  declare readonly hasPrimaryPointOfContactTarget: boolean
  declare readonly coordinatorsTarget: HTMLElement
  declare readonly inspectorsTarget: HTMLElement
  declare readonly respondersTarget: HTMLElement
  declare readonly tagSelectTarget: HTMLElement

  declare readonly locationVerifiedLatlongValue: string
  declare readonly locationOverrideLatlongValue: string
  declare readonly invalidLatlongErrorValue: string

  declare geocode: Geocode
  declare locationGeocode: Geocode
  declare hasInvalidOverrideLatlongError: boolean

  connect() {
    super.connect()

    this.geocode = new Geocode({
      verifiedLatlong: null,
      overrideLatlong: this.overrideLatlongTarget.value,
      fallback: new Geocode({
        verifiedLatlong: this.locationVerifiedLatlongValue,
        overrideLatlong: this.locationOverrideLatlongValue,
      }),
    })

    setTimeout(() => this.syncGeocode(), 500)
  }

  // latlong form input (on blur)
  handleLatlongInputOverride(event: HTMLElementEvent<HTMLInputElement>): void {
    const latlong = event.target.value

    if (isValidLatlongOrBlank(latlong)) {
      this.clearInvalidOverrideLatlongError()
      this.setOverrideLatlong(latlong)
      this.syncGeocode()
    }
  }

  // latlong form input (on input)
  validateOverrideLatlong(event: HTMLElementEvent<HTMLInputElement>) {
    const latlong = event.target.value

    if (isValidLatlongOrBlank(latlong)) {
      this.clearInvalidOverrideLatlongError()
    } else if (!this.hasInvalidOverrideLatlongError) {
      this.setInvalidOverrideLatlongError()
    }
  }

  // a pin drop or override button
  handleMapOverride({ detail: latlong }: DispatchEvent<string>): void {
    const overrideLatlong =
      (latlong && latlong.value) ||
      (this.geocode && this.geocode.getLatlong()) ||
      defaultLatlong
    this.setOverrideLatlong(overrideLatlong)
    this.syncGeocode()
  }

  // disengage the override from the button on the map legend
  handleMapReset(): void {
    this.clearOverrideLatlong()
    this.syncGeocode()
  }

  async handleLocationSelect(event: DispatchEvent) {
    const location_id = event.detail.value.value
    let location: Location

    if (location_id) {
      location = await LocalClient.fetchLocation(location_id)
    }

    // we set other fields on the form imperatively and rely on their pub controllers
    // to broadcast those values to the window if necessary
    this.setLocationLatlong(location)
    this.setPrimaryContact(location.primary_point_of_contact)
    this.setCoordinators(location.coordinators)
    this.setInspectors(location.inspectors)
    this.setResponders(location.responders)

    // dispatcher only used here because there are no corresponding DOM fields
    // for these values in the site form; if there were we could just use standard
    // pub controller style
    dispatcher(
      this,
      'contact_name',
      'site',
      location.primary_point_of_contact.name,
    )
    dispatcher(this, 'latlong', 'site', location.latlong)
    dispatcher(
      this,
      'address_line_one',
      'site',
      location.physical_address.line_one,
    )
    dispatcher(
      this,
      'address_line_two',
      'site',
      location.physical_address.line_two,
    )
    dispatcher(this, 'city', 'site', location.physical_address.city)
    dispatcher(
      this,
      'state_or_province',
      'site',
      location.physical_address.state_or_province,
    )
    dispatcher(
      this,
      'postal_code',
      'site',
      location.physical_address.postal_code,
    )
    dispatcher(
      this,
      'country_code',
      'site',
      location.physical_address.country_code,
    )
  }

  handleOrganizationSelect(event: DispatchEvent) {
    const organization_id = event.detail.value
    const disabled = organization_id === ''
    targetUpdate(this.coordinatorsTarget, { disabled })
    targetUpdate(this.inspectorsTarget, { disabled })
    targetUpdate(this.locationSelectTarget, { disabled })
    targetUpdate(this.respondersTarget, { disabled })
    targetUpdate(this.tagSelectTarget, { disabled })
  }

  private syncGeocode() {
    dispatcher(this, 'geocode', 'site', this.geocode)
  }

  private setLocationLatlong(location: Location) {
    const verifiedLatlong = location.physical_address.verified_latlong
    const overrideLatlong = location.physical_address.override_latlong
    this.geocode.fallback = new Geocode({ verifiedLatlong, overrideLatlong })

    if (!this.geocode.overrideLatlong) {
      this.overrideLatlongTarget.placeholder =
        overrideLatlong || verifiedLatlong
    }

    this.syncGeocode()
  }

  private clearOverrideLatlong() {
    this.setOverrideLatlong(null)
  }

  private setOverrideLatlong(latlong: string | null) {
    if (this.geocode) {
      this.geocode.overrideLatlong = latlong
    } else if (latlong) {
      this.geocode = new Geocode({
        verifiedLatlong: null,
        overrideLatlong: latlong,
        fallback: this.geocode.fallback,
      })
    }
    this.overrideLatlongTarget.value = latlong
  }

  private setPrimaryContact(primary_point_of_contact: SelectableUser) {
    const value = primary_point_of_contact.uuid
    targetUpdate(this.primaryPointOfContactTarget, { value })
  }

  private setCoordinators(coordinators: SelectableUser[]): void {
    const value = coordinators.map((u) => u.uuid)
    targetUpdate(this.coordinatorsTarget, { value })
  }

  private setInspectors(inspectors: SelectableUser[]): void {
    const value = inspectors.map((u) => u.uuid)
    targetUpdate(this.inspectorsTarget, { value })
  }

  private setResponders(responders: SelectableUser[]): void {
    const value = responders.map((u) => u.uuid)
    targetUpdate(this.respondersTarget, { value })
  }

  private clearInvalidOverrideLatlongError() {
    clearChildrenBySelector(this.element, 'span.override-latlong-error')
    this.hasInvalidOverrideLatlongError = false
  }

  private setInvalidOverrideLatlongError() {
    const error = document.createElement('span')
    error.setAttribute(
      'class',
      'form__field-error-message override-latlong-error',
    )
    error.innerText = this.invalidLatlongErrorValue
    this.overrideLatlongTarget.after(error)
    this.hasInvalidOverrideLatlongError = true
  }
}
