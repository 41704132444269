import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import {
  CheckCircleOutlined,
  MoreOutlined,
  PauseOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'
import { createElement, ReactElement } from 'react'

import { ComponentController } from './component_controller'
import { dispatcher } from '../pub_controller'

type IconType = 'activate' | 'deactivate' | 'suspend'

const iconMap: Record<IconType, ReactElement> = {
  activate: createElement(CheckCircleOutlined),
  deactivate: createElement(PoweroffOutlined),
  suspend: createElement(PauseOutlined),
}

type BulkActionsDropdownProps = {
  onClick: any
  selector: string
  actions?: {
    iconType: IconType
    label: string
    status: string
    confirmationMessage: string
  }[]
}

const BulkActionsDropdown = ({
  actions,
  selector,
  onClick,
}: BulkActionsDropdownProps) => {
  const items: MenuProps['items'] = (actions || []).map(
    ({ iconType, status, label }) => ({
      icon: iconMap[iconType],
      key: status,
      label: createElement(
        'a',
        { onClick: () => onClick(iconType, selector) },
        label,
      ),
    }),
  )
  const icon = createElement(MoreOutlined)
  return createElement(
    Dropdown,
    { menu: { items }, trigger: ['click'], arrow: true },
    icon,
  )
}

export class BulkActionsDropdownController extends ComponentController {
  openModal(status: string) {
    dispatcher(this, status, 'bulk-actions', 'true')
  }

  get component() {
    return BulkActionsDropdown
  }

  get initialProps() {
    const props = this.propsFromData
    return {
      ...props,
      onClick: this.openModal.bind(this),
    }
  }
}
