import React, { createElement } from 'react'
import { Table } from 'antd'
import type { TableProps } from 'antd'
import { ComponentController } from './components/component_controller'

type TableElementProps = {
  key: React.Key
  body: string
}

const columns = [
  {
    title: 'Common Status Questions',
    dataIndex: 'body',
    render: (text: string) => createElement('span', null, text),
  },
]

const TableElement = ({ rowSelection, columns, dataSource }) => {
  return createElement(Table, {
    rowSelection: { type: 'checkbox', ...rowSelection },
    columns: columns,
    dataSource: dataSource,
    pagination: false,
  })
}

export class CommonStatusQuestionsController extends ComponentController {
  static targets = ['input', 'component']

  declare inputTarget: HTMLInputElement
  declare componentTarget: HTMLInputElement

  get component() {
    return TableElement
  }

  get initialProps(): any {
    const props = this.propsFromData
    return {
      rowSelection: this.rowSelection,
      columns: columns,
      ...props,
    }
  }
  get rootElement() {
    return this.componentTarget
  }

  rowSelection: TableProps<TableElementProps>['rowSelection'] = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: TableElementProps[],
    ) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      )
      this.inputTarget.value = selectedRowKeys.join(',')
      console.log(`my input value is ${this.inputTarget.value}`)
    },
    getCheckboxProps: (record: TableElementProps) => ({
      name: record.body,
    }),
  }
}
