import { Marker } from 'mapbox-gl'
import { MapboxController } from './mapbox_controller'
import { DEFAULT_FLY_TO_ZOOM } from '../constants'

export class StaticMapController extends MapboxController {
  static values = { latlong: String, markerUrl: String }
  static targets = ['map']
  declare readonly mapTarget: HTMLInputElement
  declare readonly hasMapTarget: boolean
  declare readonly latlongValue: string
  declare readonly markerUrlValue: string

  declare verifiedMarker: Marker
  declare verifiedMarkerEl: HTMLElement

  connect() {
    this.setTokens()
    this.initializeMap(this.mapTarget)
    const [latitude, longitude] = this.latlongValue.split(',')
    this.setVerifiedMarker({ latitude, longitude })
    console.log('[map controller connected]')
  }

  protected setVerifiedMarker({ latitude, longitude, options = {} }) {
    this.clearMarkers()
    this.verifiedMarker = this.addMarker(latitude, longitude, {
      element: this.verifiedMarkerEl,
    })
    this.flyToMarkers({ opts: { maxZoom: DEFAULT_FLY_TO_ZOOM } })
  }
}
