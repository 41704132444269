import React, { createElement } from 'react'
import { Steps as StepsElement, StepsProps as AntStepProps } from 'antd'
import {
  CheckOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  ToolOutlined,
} from '@ant-design/icons'

import { ComponentController } from './component_controller'

interface StepProps {
  title: string
  description: string
  status: 'wait' | 'process' | 'finish' | 'error'
  icon: string
  steps?: string[]
}

interface CustomStepProps extends AntStepProps {
  steps?: string[]
}

interface StepElementBlockProps {
  steps: string[]
  description: string
}
interface StepElementProps {
  title: string
}
interface CustomStepsProps {
  items: StepProps[]
}

const IconMap = {
  positioning: createElement(EnvironmentOutlined, { key: 'positioning' }),
  users: createElement(TeamOutlined, { key: 'users' }),
  accessories: createElement(ToolOutlined, { key: 'accessories' }),
  check: createElement(CheckOutlined, {
    key: 'check',
    className: 'step-element__icon',
  }),
}

const StepElement: React.FC<StepElementProps> = ({ title }) => {
  return createElement('div', { key: title, className: 'step-element' }, [
    IconMap['check'],
    createElement('span', { key: title }, title),
  ])
}

const StepElementBlock: React.FC<StepElementBlockProps> = ({
  steps,
  description,
}) => {
  return createElement(
    'div',
    { key: 'step-container', className: 'step-element-block' },
    [
      createElement(
        'p',
        { key: 'description', className: 'step-element-block__description' },
        description,
      ),
      steps.map((step) => StepElement({ title: step })),
    ],
  )
}
const Steps: React.FC<CustomStepsProps> = ({ items }) => {
  const stepProps: CustomStepProps = {
    direction: 'vertical',
    items: items.map((item: StepProps) => ({
      title: item.title,
      description: item.steps
        ? StepElementBlock({ steps: item.steps, description: item.description })
        : item.description,
      status: item.status,
      icon: IconMap[item.icon],
    })),
  }
  return createElement(StepsElement, { key: 'stepsToComplete', ...stepProps })
}

export class StepsController extends ComponentController {
  get component() {
    return Steps
  }
}
