import { Controller } from '@hotwired/stimulus'

export class PizzaTrackerController extends Controller {
  static targets = ['editContainer', 'editCollapsedContainer']

  editContainerTarget: HTMLElement
  editCollapsedContainerTarget: HTMLElement

  toggleVisibility() {
    this.editContainerTarget.classList.toggle('hidden')
    this.editCollapsedContainerTarget.classList.toggle('hidden')
  }
}
