import mapboxgl, { Marker } from 'mapbox-gl'

import { DispatchEvent } from '../types'
import { DEFAULT_INITIAL_ZOOM } from '../constants'
import { MapboxAddress } from '../models'
import { MapboxController } from './mapbox_controller'

export class GeocodeableAddressableMapController extends MapboxController {
  verifiedMarker?: Marker = undefined
  verifiedMarkerEl?: HTMLImageElement = undefined

  static targets = ['map']
  declare readonly mapTarget: HTMLInputElement
  declare readonly hasMapTarget: boolean

  connect() {
    this.setTokens()
    this.initializeMap(this.mapTarget)
  }

  clear() {
    this.clearMarkers()

    this.flyToPoint({
      zoom: DEFAULT_INITIAL_ZOOM,
    })
  }

  protected resetMarkers(latitude: string, longitude: string) {
    this.setVerifiedMarker({ latitude, longitude })
  }

  protected setVerifiedMarker({ latitude, longitude, options = {} }) {
    this.clearMarkers()
    this.verifiedMarker = this.addMarker(latitude, longitude, {
      element: this.verifiedMarkerEl,
    })
    this.flyToMarkers()
  }
}
