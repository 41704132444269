import React, { useState } from 'react'
import { Cascader, CascaderProps } from 'antd'
import { ComponentController } from './component_controller'
import { dispatcher } from '../pub_controller'
import { LocalClient } from '../../clients'

interface Option {
  value?: string | number | null
  label: React.ReactNode
  children?: Option[]
  lsLeaf?: boolean
}

const StatefulCascader = (props) => {
  const [options, setOptions] = useState(props.options)
  const [value, setValue] = useState(props.value)

  const onChange = (values: string[], selectedOptions: Option[]): void => {
    props.onChange(values, selectedOptions)
    setValue(values)
  }

  // Interpret all selections with `isLeaf: false` as query parameters--- modularize
  // this function to introduce new loading strategies
  const loadData = async (selectedOptions: Option[]): Promise<void> => {
    if (props.endpoint) {
      const lastOption = selectedOptions[selectedOptions.length - 1]

      // the value of any option with dynamically loaded children is
      // a query param, e.g., "manufacturer_id=3"
      const params = selectedOptions.map((option) => option.value).join('&')
      const path = props.endpoint

      lastOption.children = await LocalClient.queryPath({ path, params })
      setOptions([...options])
    }
  }

  const changeOnSelect = true

  return React.createElement(Cascader, {
    ...props,
    onChange,
    loadData,
    changeOnSelect,
    value,
    options,
  })
}

export class CascaderController extends ComponentController {
  static targets = ['input', 'component']
  static values = { cascaderEndpoint: String }

  declare readonly componentTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement
  declare readonly cascaderEndpointValue: string

  get component() {
    return StatefulCascader
  }

  get rootElement() {
    return this.componentTarget
  }

  get initialProps() {
    const props = this.propsFromData

    return {
      ...props,
      onChange: this.onChange.bind(this),
      endpoint: this.cascaderEndpointValue,
    }
  }

  onChange(values: string[], selectedOptions: Option[]): void {
    this.updateTargets(selectedOptions)
  }

  private updateTargets(selected: Option[], updateOptions = {}): void {
    // default behavior is to take the last value in the selection, as this is is
    // really a way to navigate to a single value
    const lastSelection = selected[selected.length - 1]

    if (lastSelection && !`${lastSelection.value}`.match(/^cascader\[.*\]=/)) {
      // i.e., if there is a selection and that selection is not a filter being
      // applied to a query
      this.inputTarget.value = `${lastSelection.value}`
    } else {
      this.inputTarget.value = ''
    }

    if (!updateOptions['pubQuiet']) {
      this.inputTarget.dispatchEvent(new Event('change'))
    }

    const pubChannel = this.inputTarget.getAttribute('data-pub-channel-value')
    const pubAs = this.inputTarget.getAttribute('data-pub-as-value')

    if (selected && pubChannel && pubAs && !updateOptions['pubQuiet']) {
      const labels = selected.map((option) => option.label).join(',')
      dispatcher(this, `${pubAs}_text`, pubChannel, labels)
    }
  }

  private valueFromDOM(): string | null {
    return this.inputTarget.value || null
  }
}
