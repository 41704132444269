export interface GeocodeOptions {
  verifiedLatlong: string | null
  overrideLatlong: string | null
  fallback?: Geocode
}

export class Geocode {
  verifiedLatlong: string | null
  overrideLatlong: string | null
  fallback: Geocode

  constructor({ verifiedLatlong, overrideLatlong, fallback }: GeocodeOptions) {
    this.verifiedLatlong = verifiedLatlong
    this.overrideLatlong = overrideLatlong
    this.fallback = fallback
  }

  getLatlong() {
    return (
      this.verifiedLatlong ||
      this.overrideLatlong ||
      (this.fallback &&
        (this.fallback.verifiedLatlong || this.fallback.overrideLatlong))
    )
  }

  getVerifiedLatlong() {
    return (
      this.verifiedLatlong ||
      (this.fallback &&
        (this.fallback.overrideLatlong || this.fallback.verifiedLatlong))
    )
  }

  getOverrideLatlong() {
    return (
      this.overrideLatlong || (this.fallback && this.fallback.overrideLatlong)
    )
  }

  isVerified() {
    if (this.verifiedLatlong) {
      return true
    } else if (!this.overrideLatlong) {
      return !!(this.fallback && this.fallback.verifiedLatlong)
    } else {
      return false
    }
  }

  // a point of divergence between verification and override: if you fallback on a
  // verified geocode, that geocode should be considered verified as well, but if
  // you fall back on an override geocode, the result itself is /not/ overridden,
  // because the user may still override /that/
  isOverridden() {
    if (this.overrideLatlong) {
      return true
    } else {
      return false
    }
  }

  isFallback() {
    if (
      !this.verifiedLatlong &&
      !this.overrideLatlong &&
      this.fallback &&
      (this.fallback.verifiedLatlong || this.fallback.overrideLatlong)
    ) {
      return true
    } else {
      return false
    }
  }
}
