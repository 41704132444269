import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import {
  CalendarOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { createElement } from 'react'

import { ComponentController } from './component_controller'

type IconType =
  | 'edit'
  | 'duplicate'
  | 'delete'
  | 'cancel'
  | 'calendar'
  | 'register'
  | 'view'

type HttpMethod = 'get' | 'delete'

const iconMap: Record<IconType, JSX.Element> = {
  calendar: createElement(CalendarOutlined),
  cancel: createElement(CloseCircleOutlined),
  delete: createElement(DeleteOutlined),
  duplicate: createElement(CopyOutlined),
  edit: createElement(EditOutlined),
  register: createElement(FormOutlined),
  view: createElement(EyeOutlined),
}

type QuickActionsProps = {
  actions: {
    iconType: IconType
    label: string
    url: string
    method: HttpMethod
    className: string
  }[]
}

const QuickActions = ({ actions }: QuickActionsProps) => {
  const items: MenuProps['items'] = actions.map(
    ({ iconType, label, url, method, className }) => ({
      icon: iconMap[iconType],
      key: url,
      label: createElement(
        'a',
        { href: url, 'data-turbo-method': method, className: className },
        label,
      ),
    }),
  )
  const icon = createElement(MoreOutlined)
  return createElement(
    Dropdown,
    { menu: { items }, trigger: ['click'], arrow: true },
    icon,
  )
}

export class QuickActionsController extends ComponentController {
  get component() {
    return QuickActions
  }
}
