import { Controller } from '@hotwired/stimulus'
import { DispatchEvent } from '../types'
import { targetUpdate } from './components/component_controller'

export class ServiceOfferingRequestFormController extends Controller {
  static targets = [
    'primaryContact',
    'primaryContactEmail',
    'primaryContactPhone',
    'siteSelect',
  ]

  declare readonly primaryContactTarget: HTMLInputElement
  declare readonly primaryContactEmailTarget: HTMLInputElement
  declare readonly primaryContactPhoneTarget: HTMLInputElement
  declare readonly siteSelectTarget: HTMLSelectElement

  handleContactSelection(event: Event) {
    const target = event.target as HTMLElement
    const data = JSON.parse(target.dataset.json || '{}')

    this.primaryContactTarget.value = `${data.first_name} ${data.last_name}`
    this.primaryContactEmailTarget.value = data.email
    this.primaryContactPhoneTarget.value = data.phone || ''

    this.primaryContactTarget.blur()
  }

  async handleLocationSelectionEvent(event: DispatchEvent<string>) {
    const response = await fetch(
      `/locations/${event.detail.value.value}/sites.json`,
    )
    const sites = await response.json()
    this.updateOptions(sites)
  }

  private updateOptions(options: [string, string][]) {
    targetUpdate(this.siteSelectTarget, { options, value: '' })
    this.siteSelectTarget.dispatchEvent(new Event('change'))
  }
}
