import { Controller } from '@hotwired/stimulus'
import { DispatchEvent, PhysicalAddress } from '../types'
import { dispatcher } from './pub_controller'
import { LocalClient } from '../clients'

export class LocationSelectController extends Controller {
  async handleLocationSelectionEvent({
    detail: location_id,
  }: DispatchEvent<string>) {
    const locationId =
      location_id.value?.value || location_id.value || location_id
    const location = await LocalClient.fetchLocation(locationId)

    dispatcher(
      this,
      'clone-address',
      'verified-address',
      location.physical_address,
    )
  }
}
