import { Table } from 'antd'
import type { TableColumnsType } from 'antd'
import { createElement } from 'react'
import { ComponentController } from './component_controller'

interface DataType {
  key: string
  [key: string]: any
}

interface Props {
  columns: Array<{ title: string; key: string }>
  data: Array<DataType>
  currentUrl?: string
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
}

const AntTable = ({
  columns,
  data,
  currentUrl,
  sortColumn,
  sortDirection,
}: Props) => {
  const lastColumnIndex = columns.length - 1
  const antColumns: TableColumnsType<DataType> = columns.map(
    (
      col: { title: string; key: string; sort: boolean; hidden: boolean },
      index: number,
    ) => ({
      title: col.hidden ? '' : col.title,
      dataIndex: col.key,
      key: col.key,
      sorter: col.sort,
      sortOrder:
        sortColumn === col.key
          ? sortDirection === 'asc'
            ? 'ascend'
            : 'descend'
          : undefined,
      className: sortColumn === col.key ? 'highlight' : '',
      fixed: index === lastColumnIndex ? 'right' : undefined,
      render: (text: string) => {
        return createElement('div', {
          dangerouslySetInnerHTML: { __html: text },
        })
      },
    }),
  )

  const handleChange = (_: any, __: any, sorter: any) => {
    const direction = sorter.order === 'ascend' ? 'asc' : 'desc'
    const url = new URL(currentUrl, window.location.origin)
    if (currentUrl && sorter.column) {
      url.searchParams.set('sort', sorter.column.key)
      url.searchParams.set('direction', direction)
      window.location.href = url.toString()
    } else {
      url.searchParams.delete('sort')
      url.searchParams.delete('direction')
      window.location.href = url.toString()
    }
  }

  return createElement(Table<DataType>, {
    bordered: true,
    columns: antColumns,
    dataSource: data,
    scroll: { x: true },
    pagination: false,
    onChange: handleChange,
  })
}

export class AntTableController extends ComponentController {
  get component() {
    return AntTable
  }
}
