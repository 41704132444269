import { createElement } from 'react'

export const AedIcon = ({ className }) =>
  createElement(
    'span',
    { className, role: 'img', 'aria-label': 'aed' },
    createElement(
      'svg',
      {
        width: '11',
        height: '11',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      createElement(
        'g',
        { clipPath: 'url(#a)', fill: '#000', fillOpacity: '.85' },
        createElement('path', {
          d: 'M9.371 2.202c-.996-.503-2.213-.22-3.01.577l-.524.525-.588-.598a2.583 2.583 0 0 0-4.143.703c-.514.986-.22 2.213.577 3l3.944 3.943a.298.298 0 0 0 .42 0l1.038-1.038-.797-1.458a.968.968 0 0 1 .01-.955.95.95 0 0 1 .724-.461l-.23-.598a.965.965 0 0 1 .89-1.311h.011l1.196.01c.44.01.808.305.923.724l.273 1.049c1.174-1.196.933-3.262-.714-4.112ZM4.735 4.94a.094.094 0 0 1-.094.094h-.682v.682a.094.094 0 0 1-.094.095h-.672a.094.094 0 0 1-.094-.095v-.682h-.682a.094.094 0 0 1-.094-.094v-.671c0-.053.042-.095.094-.095h.682v-.692c0-.052.042-.094.094-.094h.672c.052 0 .094.042.094.094v.682h.682c.052 0 .094.042.094.094v.682Z',
        }),
        createElement('path', {
          d: 'M9.11 5.457a.246.246 0 0 0-.22-.178l-1.207-.01a.234.234 0 0 0-.21.324l.619 1.595-.965-.01a.225.225 0 0 0-.2.114.227.227 0 0 0 0 .231l1.563 2.832c.032.063.084.095.147.116.042.01.095.01.137-.01a.234.234 0 0 0 .147-.263l-.368-1.71 1.05.01a.263.263 0 0 0 .188-.083.222.222 0 0 0 .042-.2L9.11 5.457Z',
        }),
      ),
      createElement(
        'defs',
        null,
        createElement(
          'clipPath',
          { id: 'a' },
          createElement('path', {
            fill: '#fff',
            transform: 'translate(.667 .667)',
            d: 'M0 0h10.333v10.333H0z',
          }),
        ),
      ),
    ),
  )
