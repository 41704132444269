import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/es/date-picker'
import dayjs, { Dayjs } from 'dayjs'
import { ComponentController } from './component_controller'
import { DispatchEvent } from '../../types'
import { dispatcher } from '../pub_controller'

export class DatePickerController extends ComponentController<DatePickerProps> {
  static targets = ['component', 'input']

  declare componentTarget: HTMLElement
  declare inputTarget: HTMLInputElement
  declare datetimeField: string
  declare fieldId: string

  get component() {
    return DatePicker
  }

  get initialProps() {
    const props = this.propsFromData
    const url = new URL(window.location.href)
    this.datetimeField = props['datetime_field']
    this.fieldId = props.id
    const date =
      props[this.datetimeField] ||
      url.searchParams.get('datetimeField') ||
      props['value']

    let initialValue
    if (date) {
      initialValue = dayjs(date)
    }

    return {
      ...props,
      onChange: this.onChange.bind(this),
      value: initialValue,
    }
  }

  get rootElement() {
    return this.componentTarget
  }

  update(event: DispatchEvent<{ content: string }>) {
    const value = this.getPropsFromEvent(event)['value']
    this.updateTargets(value)
    super.update(event)
  }

  onChange(date: any, dateString: string): void {
    this.updateTargets(date)
    this.updateProps({ value: date })

    const dateInput = document.getElementById(this.fieldId) as HTMLInputElement

    if (dateInput) {
      dateInput.value = dayjs(dateString).format('YYYY-MM-DD')
    }
  }

  reset() {
    this.updateProps({ value: null })
  }

  private updateTargets(date: Dayjs | null): void {
    const field = document.getElementById(this.fieldId) as HTMLInputElement
    field.value = date ? date.format('YYYY-MM-DD') : ''
    field.dispatchEvent(new Event('change'))

    this.updateDateTimeField(date)

    const pubChannel = field.getAttribute('data-pub-channel-value')
    const pubAs = field.getAttribute('data-pub-as-value')

    if (pubChannel && pubAs) {
      dispatcher(this, `${pubAs}_text`, pubChannel, date)
    }
  }

  private updateDateTimeField(date: Dayjs | null): void {
    const field = document.getElementById(
      this.datetimeField,
    ) as HTMLInputElement

    if (date) {
      const currentValue = field.value
      const currentDateTime = currentValue ? dayjs(currentValue) : null

      if (currentDateTime) {
        const newDateTime = date
          .hour(currentDateTime.hour())
          .minute(currentDateTime.minute())
          .second(currentDateTime.second())
          .millisecond(currentDateTime.millisecond())

        field.value = newDateTime.format('YYYY-MM-DD HH:mm:ss')
      } else {
        field.value = date.format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      field.value = ''
    }

    field.dispatchEvent(new Event('change'))
  }
}
