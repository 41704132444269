import { Dropdown, Space } from 'antd'
import {
  DownOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  ImportOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { createElement, forwardRef } from 'react'

import { ComponentController } from './component_controller'
import { AedIcon } from './custom_icons'

type IconType = 'aed' | 'import' | 'plus' | 'file' | 'pdf' | 'excel'

const iconMap: Record<IconType, JSX.Element> = {
  aed: createElement(AedIcon),
  import: createElement(ImportOutlined),
  plus: createElement(PlusOutlined),
  file: createElement(FileOutlined),
  pdf: createElement(FilePdfOutlined),
  excel: createElement(FileExcelOutlined),
}

type ButtonDropdownProps = {
  actions: { icon?: IconType; label: string; url: string }[]
  className?: string
  label: string
}

type ButtonDropdownTargetProps = {
  className?: string
  label: string
}

const ButtonDropdownTarget = forwardRef(
  ({ className, label, ...rest }: ButtonDropdownTargetProps, ref) =>
    createElement(
      'button',
      {
        className,
        ref,
        ...rest,
      },
      createElement(Space, null, label, createElement(DownOutlined)),
    ),
)
const ButtonDropdown = ({
  actions,
  className = 'button button--medium button--primary',
  label,
}: ButtonDropdownProps) => {
  const items: MenuProps['items'] = actions.map(({ icon, label, url }) => ({
    key: url,
    icon: icon ? iconMap[icon] : undefined,
    label: createElement('a', { href: url, 'data-turbo': false }, label),
  }))
  const button = createElement(ButtonDropdownTarget, { className, label })
  return createElement(
    Dropdown,
    { menu: { items }, trigger: ['click'] },
    button,
  )
}

export class ButtonDropdownController extends ComponentController {
  get component() {
    return ButtonDropdown
  }
}
