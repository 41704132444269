import { Controller } from '@hotwired/stimulus'
import { LocalClient } from '../clients'
import { targetUpdate } from './components/component_controller'
import { DispatchEvent, SelectOption } from '../types/'

interface OptionsFromApi {
  options: SelectOption[]
}

export class DeviceFormController extends Controller {
  static targets = [
    'organization',
    'deviceType',
    'serialNumber',
    'location',
    'site',
    'placement',
  ]

  declare readonly organizationTarget: HTMLInputElement
  declare readonly deviceTypeTarget: HTMLInputElement
  declare readonly serialNumberTarget: HTMLInputElement
  declare readonly locationTarget: HTMLSelectElement
  declare readonly siteTarget: HTMLSelectElement
  declare readonly placementTarget: HTMLInputElement

  declare locationId: string | null
  declare organizationId: string | null

  public async handleOrganizationSelect(event: DispatchEvent): Promise<void> {
    const organizationId = event.detail.value
    if (organizationId != this.organizationId) {
      this.organizationId = organizationId
      this.locationId = null
      const locations = await this.getOrganizationLocations()

      this.setLocationSelectOptions(locations.options)
      this.disableSiteSelect()
      this.disablePlacementField()
    }
  }

  public async handleLocationSelect(event: DispatchEvent): Promise<void> {
    const locationId = event.detail.value
    if (locationId != this.locationId) {
      this.locationId = locationId
      const sites = await this.getLocationSites()
      this.setSiteSelectOptions(sites.options)
      this.disablePlacementField()
    }
  }

  public handleSiteSelect(event: DispatchEvent): void {
    this.enablePlacementField()
  }

  public clearForm() {
    this.clearOrganizationCascader()
    this.clearDeviceTypeCascader()

    this.setSerialNumberValue('')
    this.setPlacementValue('')

    this.disableLocationSelect()
    this.disableSiteSelect()
    this.disablePlacementField()
  }

  // form state

  private clearOrganizationCascader(): void {}
  private clearDeviceTypeCascader(): void {}

  private disableLocationSelect(): void {
    targetUpdate(this.locationTarget, {
      options: [],
      disabled: true,
      value: null,
    })
  }

  private setLocationSelectOptions(options: SelectOption[]): void {
    targetUpdate(this.locationTarget, {
      options: options,
      disabled: false,
      value: null,
    })
  }

  private disableSiteSelect(): void {
    targetUpdate(this.siteTarget, { options: [], disabled: true, value: null })
  }

  private setSiteSelectOptions(options: SelectOption[]): void {
    targetUpdate(this.siteTarget, {
      options: options,
      disabled: false,
      value: null,
    })
  }

  private disablePlacementField(): void {
    this.placementTarget.value = ''
    this.placementTarget.setAttribute('disabled', 'true')
    this.placementTarget.setAttribute('readonly', 'true')
  }
  private enablePlacementField(): void {
    this.placementTarget.removeAttribute('disabled')
    this.placementTarget.removeAttribute('readonly')
  }

  private setSerialNumberValue(value: string): void {}
  private setPlacementValue(value: string): void {}

  // network

  private async getOrganizationLocations(): Promise<OptionsFromApi> {
    if (!this.organizationId) {
      throw new Error('need an organization id to load locations')
    }

    const path = '/organization_locations'
    const params = `organization_id=${this.organizationId}`
    return await LocalClient.queryPath({ path, params })
  }

  private async getLocationSites(): Promise<OptionsFromApi> {
    if (!this.locationId) {
      throw new Error('need a location id to load sites')
    }

    const path = `/locations/sites`
    const params = `location_ids=${this.locationId}`
    return await LocalClient.queryPath({ path, params })
  }
}
