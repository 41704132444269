import { Controller } from '@hotwired/stimulus'
import { DispatchEvent } from '../types'
import { targetUpdate } from './components/component_controller'

export class ServiceOfferingTypeFormController extends Controller {
  static targets = ['subType']

  declare readonly subTypeTarget: HTMLSelectElement

  handleSubTypeResults(event: DispatchEvent) {
    const options = event.detail.content
    this.updateSubTypes(options)
  }

  private updateSubTypes(options: Array<[string, string]>) {
    var value = ''
    if (options.length == 1) {
      value = options[0]['value']
    }
    options.unshift(['', ''])
    targetUpdate(this.subTypeTarget, { options, value: value })
    this.subTypeTarget.dispatchEvent(new Event('change'))
  }
}
