import React, { createElement } from 'react'

import { Progress } from 'antd'

import { ComponentController } from './component_controller'

interface ProgressBarProps {
  percent: number
  label: string
}
const ProgressBar: React.FC<ProgressBarProps> = ({ percent, label }) => {
  return createElement(Progress, {
    percent,
    showInfo: false,
    'aria-label': label,
  })
}

export class ProgressBarController extends ComponentController {
  get component() {
    return ProgressBar
  }
}
