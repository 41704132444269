import { Controller } from '@hotwired/stimulus'
import mapboxgl, {
  FitBoundsOptions,
  LngLatBounds,
  LngLatBoundsLike,
  Map,
  MapboxOptions,
  Marker,
  MarkerOptions,
} from 'mapbox-gl'

import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_FLY_TO_ZOOM,
  DEFAULT_INITIAL_ZOOM,
} from '../constants'
import { getMbPub, getMbST, mapboxToLatlong } from '../utils'
import { Geocodeable } from '../types'

export class MapboxController extends Controller {
  declare accessToken: string
  declare sessionToken: string
  map: Map
  markers: Marker[] = []

  public getMapCenterLatlong() {
    return mapboxToLatlong(this.map.getCenter())
  }

  protected get testMode() {
    return this.accessToken === ''
  }

  protected setTokens(): void {
    this.accessToken = getMbPub()
    this.sessionToken = getMbST()

    mapboxgl.accessToken = this.accessToken
  }

  protected initializeMap(
    container: string | HTMLElement,
    options?: MapboxOptions,
  ) {
    this.map = new Map({
      attributionControl: false,
      center: [Number(DEFAULT_LONGITUDE), Number(DEFAULT_LATITUDE)],
      testMode: this.testMode,
      zoom: DEFAULT_INITIAL_ZOOM,
      ...options,
      container: container,
    })
    this.map.addControl(new mapboxgl.FullscreenControl())
  }

  protected addMarker(
    latitude: string,
    longitude: string,
    markerOptions: MarkerOptions = {},
  ) {
    const marker = new mapboxgl.Marker(markerOptions)
      .setLngLat([Number(longitude), Number(latitude)])
      .addTo(this.map)

    this.markers.push(marker)

    return marker
  }

  protected clearMarkers(markers = this.markers) {
    const currentMarkers = this.markers

    markers.forEach((marker) => marker?.remove())
    markers = currentMarkers.filter((marker) => !markers.includes(marker))
  }

  protected flyToMarkers({
    markers = this.markers,
    opts,
  }: { markers?: Marker[]; opts?: FitBoundsOptions } = {}) {
    const geocodedMarkers = this.geocodeMarkers(markers)
    this.flyToBoundingBox(
      this.calculateBoundsForGeocodables(geocodedMarkers),
      opts,
    )
  }

  protected plotGeocodables(geocodeables: Geocodeable[]) {
    geocodeables.forEach(({ latlong }) => {
      const [latitude, longitude] = latlong.split(',')

      this.addMarker(latitude, longitude)
    })
  }

  protected flyToBoundingBox(
    bounds: LngLatBoundsLike,
    opts: FitBoundsOptions,
  ): void {
    this.map.fitBounds(bounds, {
      padding: 50,
      ...opts,
    })
  }

  protected flyToPoint({
    latitude = DEFAULT_LATITUDE,
    longitude = DEFAULT_LONGITUDE,
    zoom = DEFAULT_FLY_TO_ZOOM,
    callback = () => {},
  } = {}) {
    this.map.flyTo({
      center: [Number(longitude), Number(latitude)],
      zoom: zoom,
      essential: true,
    })

    this.map.once('moveend', callback)
  }

  private geocodeMarkers(markers = this.markers) {
    return this.markers.map((marker) => {
      const { lat, lng } = marker.getLngLat()

      return {
        latlong: `${lat},${lng}`,
      }
    })
  }

  private calculateBoundsForGeocodables(geocodeables: Geocodeable[]) {
    const { latitudes, longitudes } = this.calculateLatsAndLongs(geocodeables)

    return this.calculateLngLatBounds(latitudes, longitudes)
  }

  private calculateLngLatBounds(latitudes: number[], longitudes: number[]) {
    const maxLat = Math.max(...latitudes)
    const minLat = Math.min(...latitudes)
    const maxLong = Math.max(...longitudes)
    const minLong = Math.min(...longitudes)

    const sw: [number, number] = [minLong, minLat]
    const ne: [number, number] = [maxLong, maxLat]

    return new LngLatBounds(sw, ne)
  }

  private calculateLatsAndLongs(geocodeables: Geocodeable[]) {
    return geocodeables.reduce(
      (acc, geocodeable) => {
        const [lat, long] = geocodeable.latlong.split(',')

        acc.latitudes.push(Number(lat))
        acc.longitudes.push(Number(long))

        return acc
      },
      { latitudes: [], longitudes: [] },
    )
  }
}
