import {
  MapboxAddressResponsePayload,
  Location,
  StatesAndProvincesPayload,
} from '../types'

type FetchParams = {
  path?: string
  params?: Record<string, string> | string
  preserveExistingParams?: boolean
}

type QueryCurrentPathParams = Omit<FetchParams, 'path'>

export class LocalClient {
  private FORMAT = '.json'
  private SUGGESTED_ADDRESS_DETAILS_PATH = '/suggested_address_details'
  private STATE_PROVINCE_DETAILS_PATH = '/country_states'

  static queryPath<T>({
    path,
    params,
    preserveExistingParams,
  }: FetchParams = {}): Promise<T> {
    return new LocalClient().fetch({ path, params, preserveExistingParams })
  }

  static queryCurrentPath<T>({
    params,
    preserveExistingParams,
  }: QueryCurrentPathParams = {}): Promise<T> {
    return new LocalClient().queryCurrentPath({
      params,
      preserveExistingParams,
    })
  }
  static fetchSuggestedAddressDetails(
    full_address: string,
  ): Promise<MapboxAddressResponsePayload> {
    return new LocalClient().fetchSuggestedAddressDetails(full_address)
  }

  static async getStatesAndProvincesFromCountryCode(
    country_code: string,
  ): Promise<StatesAndProvincesPayload> {
    return new LocalClient().getStatesAndProvincesFromCountryCode(country_code)
  }

  static async fetchLocation(location_id: string): Promise<Location> {
    return new LocalClient().fetch<Location>({
      path: `${
        new URL(document.location.href).origin
      }/locations/${location_id}`,
    })
  }

  async getStatesAndProvincesFromCountryCode(
    country_code: string,
  ): Promise<StatesAndProvincesPayload> {
    const response = await this.fetch<StatesAndProvincesPayload>({
      path: this.STATE_PROVINCE_DETAILS_PATH,
      params: { country_code },
    })
    return response
  }

  private async queryCurrentPath<T>({
    params,
    preserveExistingParams,
  }: QueryCurrentPathParams = {}): Promise<T> {
    return this.fetch<T>({ params, preserveExistingParams })
  }

  private async fetchSuggestedAddressDetails(
    full_address: string,
  ): Promise<MapboxAddressResponsePayload> {
    return this.fetch<MapboxAddressResponsePayload>({
      path: this.SUGGESTED_ADDRESS_DETAILS_PATH,
      params: { q: full_address },
    })
  }

  private async fetch<T>({
    path,
    params,
    preserveExistingParams,
  }: FetchParams = {}): Promise<T> {
    const newParams = new URLSearchParams(params)
    const { pathname, searchParams: existingParams } = new URL(
      document.location.href,
    )
    const searchParams = preserveExistingParams
      ? new URLSearchParams([...existingParams, ...newParams])
      : newParams

    const res = await fetch(
      `${path || pathname}${this.FORMAT}?${searchParams.toString()}`,
    )

    return (await res.json()) as T
  }
}
