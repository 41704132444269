import { Controller } from '@hotwired/stimulus'
import { DispatchEvent } from '../types'
import { Geocode } from '../models'
import { dispatcher } from './pub_controller'
import { MapboxController } from './mapbox_controller'
import { getEventName, clearChildrenBySelector } from '../utils'

export class MapWithLegendController extends Controller {
  static targets = [
    'latlong',
    'title',
    'addressIcon',
    'overrideButton',
    'resetPinButton',
  ]
  static values = {
    buttonOverrideText: String,
    buttonResetText: String,
    pubChannel: String,
    verifiedIconPath: String,
    unverifiedIconPath: String,
    unverifiedTitleError: String,
    verifiedIconAltText: String,
    unverifiedIconAltText: String,
    legendOverrideLatlongText: String,
  }

  static outlets = ['overrideable-geocodeable-addressable-map']

  declare readonly latlongTarget: HTMLElement
  declare readonly titleTarget: HTMLElement
  declare readonly addressIconTarget: HTMLElement
  declare readonly overrideButtonTarget: HTMLElement
  declare readonly resetPinButtonTarget: HTMLElement

  declare readonly buttonOverrideTextValue: string
  declare readonly buttonResetTextValue: string
  declare readonly pubChannelValue: string
  declare readonly verifiedIconPathValue: string
  declare readonly unverifiedIconPathValue: string
  declare readonly unverifiedTitleErrorValue: string
  declare readonly verifiedIconAltTextValue: string
  declare readonly unverifiedIconAltTextValue: string
  declare readonly legendOverrideLatlongTextValue: string

  declare readonly overrideableGeocodeableAddressableMapOutlet: MapboxController

  declare geocode: Geocode
  declare hasUnverifiedText: boolean
  declare hasOverrideLatlongText: boolean

  connect() {
    super.connect()
    this.geocode = null
    this.hasUnverifiedText = false
    this.hasOverrideLatlongText = false
  }

  latlong(): string {
    return this.geocode && this.geocode.getLatlong()
  }

  handleOverrideButton(): void {
    dispatcher(
      this,
      'map-override',
      this.pubChannelValue,
      this.currentMapCenter(),
    )
  }

  handleResetPinButton(): void {
    dispatcher(this, 'map-reset', this.pubChannelValue, null)
  }

  isClear(): boolean {
    // if there is no geocode, or a geocode with a fallback but no other values,
    // we consider the form clear
    return (
      !this.geocode ||
      (this.geocode.fallback &&
        !(this.geocode.verifiedLatlong || this.geocode.overrideLatlong))
    )
  }

  isVerified(): boolean {
    return this.geocode && this.geocode.isVerified()
  }

  isOverridden(): boolean {
    return this.geocode && this.geocode.isOverridden()
  }

  updateGeocode({ detail: geocode }: DispatchEvent<Geocode>): void {
    this.geocode = geocode
    this.sync()
  }

  sync(): void {
    this.syncAddressLabel()
    this.syncLatlong()
    this.syncButton()
  }

  syncAddressLabel(): void {
    if (this.isVerified() || this.isClear()) {
      // remove the "(Unverified)" text and icon
      clearChildrenBySelector(this.element, '.legend-title-unverified')
      clearChildrenBySelector(this.addressIconTarget, 'img')

      // set the unslashed icon
      const iconTagElement = document.createElement('img')
      iconTagElement.setAttribute('src', this.verifiedIconPathValue)
      iconTagElement.setAttribute('class', 'legend-title-verified-icon')
      iconTagElement.setAttribute('height', '21')
      iconTagElement.setAttribute('width', '21')
      iconTagElement.setAttribute('alt', this.verifiedIconAltTextValue)
      this.addressIconTarget.append(iconTagElement)

      this.hasUnverifiedText = false
    } else if (!this.hasUnverifiedText) {
      // add the "(Unverified)" text
      const element = document.createElement('span')
      element.className = 'legend-title-unverified'
      element.innerText = this.unverifiedTitleErrorValue
      this.titleTarget.append(element)

      // remove the verified icon and replace with the slashed one
      clearChildrenBySelector(this.addressIconTarget, 'img')
      const iconTagElement = document.createElement('img')
      iconTagElement.setAttribute('src', this.unverifiedIconPathValue)
      iconTagElement.setAttribute('class', 'legend-title-unverified-icon')
      iconTagElement.setAttribute('height', '21')
      iconTagElement.setAttribute('width', '21')
      iconTagElement.setAttribute('alt', this.unverifiedIconAltTextValue)
      this.addressIconTarget.append(iconTagElement)

      this.hasUnverifiedText = true
    }
  }

  syncLatlong(): void {
    const latlong =
      (this.geocode &&
        (this.geocode.overrideLatlong || this.geocode.verifiedLatlong)) ||
      null
    this.latlongTarget.innerText = latlong

    if (
      this.geocode &&
      this.geocode.overrideLatlong &&
      !this.hasOverrideLatlongText
    ) {
      // add the "Move your map pin to update" text
      const element = document.createElement('div')
      element.className = 'legend-latlong-override map-with-legend__detail'
      element.innerText = this.legendOverrideLatlongTextValue
      this.latlongTarget.after(element)
      this.hasOverrideLatlongText = true
    } else if (
      !this.geocode ||
      (this.geocode && !this.geocode.overrideLatlong)
    ) {
      clearChildrenBySelector(this.element, '.legend-latlong-override')
      this.hasOverrideLatlongText = false
    }
  }

  syncButton(): void {
    if (this.isOverridden()) {
      this.overrideButtonTarget.classList.add('hidden')
      this.resetPinButtonTarget.classList.remove('hidden')
    } else {
      this.resetPinButtonTarget.classList.add('hidden')
      this.overrideButtonTarget.classList.remove('hidden')
    }
  }

  currentMapCenter(): string {
    return this.overrideableGeocodeableAddressableMapOutlet.getMapCenterLatlong()
  }
}
