import { TimePicker } from 'antd'
import { TimePickerProps } from 'antd/es/time-picker'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ComponentController } from './component_controller'
import { DispatchEvent } from '../../types'
import { dispatcher } from '../pub_controller'

export class TimePickerController extends ComponentController<TimePickerProps> {
  static targets = ['component', 'input']

  declare componentTarget: HTMLElement
  declare inputTarget: HTMLInputElement
  declare datetimeField: string
  declare fieldId: string

  get component() {
    return TimePicker
  }

  get initialProps() {
    dayjs.extend(utc)
    const props = this.propsFromData
    const url = new URL(window.location.href)
    this.datetimeField = props['datetime_field']
    this.fieldId = props.id

    const datetime =
      props[this.fieldId] ||
      url.searchParams.get(this.fieldId) ||
      props['value']

    let initialValue
    if (datetime) {
      initialValue = dayjs(datetime)
    }

    return {
      ...props,
      onChange: this.onChange.bind(this),
      value: initialValue,
    }
  }

  get rootElement() {
    return this.componentTarget
  }

  update(event: DispatchEvent<{ content: string }>) {
    const value = this.getPropsFromEvent(event)['value']
    this.updateTargets(value)
    super.update(event)
  }

  onChange(time: any, timeString: string): void {
    this.updateTargets(time)
    this.updateProps({ value: time })

    const timeInput = document.getElementById(this.fieldId) as HTMLInputElement

    if (timeInput) {
      timeInput.value = dayjs(timeString, 'h:mm a').format('h:mm a')
    }
  }

  reset() {
    this.updateProps({ value: null })
  }

  private updateTargets(time: Dayjs | null): void {
    const field = document.getElementById(this.fieldId) as HTMLInputElement
    field.value = time ? time.format('h:mm a') : ''
    field.dispatchEvent(new Event('change'))

    this.updateDateTimeField(time)

    const pubChannel = field.getAttribute('data-pub-channel-value')
    const pubAs = field.getAttribute('data-pub-as-value')

    if (pubChannel && pubAs) {
      dispatcher(this, `${pubAs}_text`, pubChannel, time)
    }
  }

  private updateDateTimeField(time: Dayjs | null): void {
    const field = document.getElementById(
      this.datetimeField,
    ) as HTMLInputElement

    if (time) {
      const currentValue = field.value
      const currentDateTime = currentValue ? dayjs(currentValue) : null

      if (currentDateTime) {
        const newDateTime = currentDateTime
          .hour(time.hour())
          .minute(time.minute())
          .second(time.second())
          .millisecond(time.millisecond())

        field.value = newDateTime.utc().format('YYYY-MM-DD HH:mm:ss')
      } else {
        field.value = time.utc().format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      field.value = ''
    }

    field.dispatchEvent(new Event('change'))
  }
}
